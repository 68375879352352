import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Container from "./Container"
import Button from "../common/Button"
import { Link } from "gatsby"

const DATA = {
  title: "The ultimate bike fitting",
  descriptions: [
    "Changing your position on the bicycle has a profound effect on your movement economy. With PNOĒ, you can test different cycling positions to find the one that maximizes your energy efficiency and allows you to move the most with the least amount of effort.",
  ],
  button: {
    name: "Dive into the PNOĒ app",
    to: "/pnoe-coach",
  },
}

export default function BikeFitting() {
  return (
    <Container bleed="right">
      <Container.Absolute>
        <Container.Title width="50rem">{DATA.title}</Container.Title>
        <Container.Description width="40rem">
          {DATA.descriptions[0]}
        </Container.Description>
        <Button as={Link} to={DATA.button.to}>
          {DATA.button.name}
        </Button>
      </Container.Absolute>
      <Container.Image align="right">
        <StaticImage
          src="../../assets/images/why-get-tested/bike-fitting.png"
          alt="bicycle exercise"
          width={846}
          placeholder="blurred"
        />
      </Container.Image>
    </Container>
  )
}

BikeFitting.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="50rem">{DATA.title}</Container.Title>
    <StaticImage
      src="../../assets/images/why-get-tested/bike-fitting.png"
      alt="bicycle exercise"
      width={846}
      placeholder="blurred"
    />
    <Container.Description width="40rem">
      {DATA.descriptions[0]}
    </Container.Description>
    <Button as={Link} to={DATA.button.to}>
      {DATA.button.name}
    </Button>
  </Container>
)
