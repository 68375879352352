import React from "react"
import { DEVICES } from "../../config"
import useWindowSize from "../../hooks/useWindowSize"
import { StaticImage } from "gatsby-plugin-image"
import Container from "./Container"
import Button from "../common/Button"
import { Link } from "gatsby"

const DATA = {
  title: "Power & Heart rate training zones",
  descriptions: [
    "To get the most out of your training, you must train in specific training intensities (AKA heart rate or power zones) specific to your biology. When calculated by smartwatches or equations, these training zones may be as much as 25% off. The PNOĒ test is the most accurate method for calculating them, while the PNOĒ app lets you sync them with any heart rate monitor.",
  ],
  button: {
    name: "Dive into the PNOĒ app",
    to: "/pnoe-coach",
  },
}

export default function TrainingZones() {
  return (
    <Container bleed="left">
      <StaticImage
        src="../../assets/images/why-get-tested/training-zones.png"
        alt="bicycle exercise"
        width={832}
        placeholder="blurred"
      />
      <div>
        <Container.Title width="50rem">{DATA.title}</Container.Title>
        <Container.Description width="45rem">
          {DATA.descriptions[0]}
        </Container.Description>
        <Button as={Link} to={DATA.button.to}>
          {DATA.button.name}
        </Button>
      </div>
    </Container>
  )
}

TrainingZones.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="50rem">{DATA.title}</Container.Title>
    <StaticImage
      src="../../assets/images/why-get-tested/training-zones.png"
      alt="bicycle exercise"
      width={832}
      placeholder="blurred"
    />
    <Container.Description width="45rem" mt={24}>
      {DATA.descriptions[0]}
    </Container.Description>
    <Button as={Link} to={DATA.button.to}>
      {DATA.button.name}
    </Button>
  </Container>
)
