import React, { useState } from "react"
import Header from "./Header"
import GlobalStyles from "./GlobalStyles"
import { DARK_THEME } from "../../theme"
import { ThemeProvider } from "styled-components"
import Footer from "./Footer"

function Layout({ children, theme = DARK_THEME, headerTheme = "light" }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles isMenuOpen={isMenuOpen} />
      <Header
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        theme={headerTheme}
      />
      {children}
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
