import React from "react"
import styled from "styled-components"
import Container from "../common/Container"

const Divider = styled.hr`
  height: 2px;
  box-shadow: -1px -1px 3px 0 var(--color-shadow-one),
    1px 1px 3px 0 var(--color-shadow-two);
  background-color: var(--main);
  border: none;
`

const LightDivider = () => (
  <Container
    as={Divider}
    light
    css={`
      margin: 0;
      position: relative;
      z-index: 11;
    `}
  ></Container>
)

Divider.Light = LightDivider

export default Divider
