import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Container from "./Container"
import Button from "../common/Button"
import { Link } from "gatsby"

const DATA = {
  title: "Why Breath Analysis matters",
  descriptions: [
    "Metabolism is the fundamental process that keeps your body alive and moving by using the oxygen you breathe to convert nutrients (i.e., fats, carbs, protein) into energy. 10,000+ studies have established Breath Analysis as the most reliable method for analyzing this process and leading you to optimal performance. Scroll down to find how.",
  ],
  button: {
    name: "Dive into the science",
    to: "/the-science",
  },
}

const Image = () => (
  <Container.Image align="center">
    <StaticImage
      src="../../assets/images/why-get-tested/breath-analysis.png"
      alt="breath analysis graph"
      width={336}
      placeholder="blurred"
    />
  </Container.Image>
)

export default function BreathAnalysis() {
  return (
    <Container bleed="both" py="10rem">
      <div>
        <Container.Title>{DATA.title}</Container.Title>
        <Container.Description>{DATA.descriptions[0]}</Container.Description>
        <Button as={Link} to={DATA.button.to}>
          {DATA.button.name}
        </Button>
      </div>
      <Image />
    </Container>
  )
}

BreathAnalysis.Mobile = () => (
  <Container bleed="both" py="10rem">
    <Container.Title>{DATA.title}</Container.Title>
    <Container.Description>{DATA.descriptions[0]}</Container.Description>
    <Image />
    <Button mt={24} as={Link} to={DATA.button.to}>
      {DATA.button.name}
    </Button>
  </Container>
)
