import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Container from "./Container"

const DATA = {
  title: "Movement economy",
  descriptions: [
    "The PNOĒ test offers the gold standard in measuring the amount of energy (i.e., calories) your body consumes during exercise. By measuring mechanical power simultaneously (i.e., watts for cycling, pace for running), you accurately measure movement economy and determine the exercise intensity where your body works most efficiently.",
  ],
}

export default function MovementEconomy() {
  return (
    <Container bleed="left">
      <StaticImage
        src="../../assets/images/why-get-tested/movement-economy.png"
        alt="bicycle exercise"
        width={846}
        placeholder="blurred"
      />
      <div>
        <Container.Title width="50rem">{DATA.title}</Container.Title>
        <Container.Description width="40rem">
          {DATA.descriptions[0]}
        </Container.Description>
      </div>
    </Container>
  )
}

MovementEconomy.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="50rem">{DATA.title}</Container.Title>
    <Container.Description width="40rem">
      {DATA.descriptions[0]}
    </Container.Description>
    <StaticImage
      src="../../assets/images/why-get-tested/movement-economy-mobile.png"
      alt="bicycle exercise"
      placeholder="blurred"
    />
  </Container>
)
