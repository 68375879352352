import React from "react"
import { DEVICES } from "../config"
import useWindowSize from "../hooks/useWindowSize"
import Layout from "../components/common/Layout"
import SEO from "../components/common/SEO"
import Divider from "../components/common/Divider"
import Hero from "../components/why-get-tested/Hero"
import BreathAnalysis from "../components/why-get-tested/BreathAnalysis"
import LimitingFactor from "../components/why-get-tested/LimitingFactor"
import MovementEconomy from "../components/why-get-tested/MovementEconomy"
import BikeFitting from "../components/why-get-tested/BikeFitting"
import TrainingZones from "../components/why-get-tested/TrainingZones"
import RaceFueling from "../components/why-get-tested/RaceFueling"
import Accuracy from "../components/why-get-tested/Accuracy"

export default function WhyGetTestedPage() {
  const { width } = useWindowSize()

  return (
    <Layout>
      <SEO title="Why get tested" />
      <Divider.Light />
      {width < DEVICES.desktop ? (
        <>
          <Hero.Mobile />
          <Divider.Light />
          <BreathAnalysis.Mobile />
          <Divider.Light />
          <LimitingFactor.Mobile />
          <Divider.Light />
          <MovementEconomy.Mobile />
          <Divider.Light />
          <BikeFitting.Mobile />
          <Divider.Light />
          <TrainingZones.Mobile />
          <Divider.Light />
          <RaceFueling.Mobile />
          <Divider.Light />
          <Accuracy />
        </>
      ) : (
        <>
          <Hero />
          <Divider.Light />
          <BreathAnalysis />
          <Divider.Light />
          <LimitingFactor />
          <Divider.Light />
          <MovementEconomy />
          <Divider.Light />
          <BikeFitting />
          <Divider.Light />
          <TrainingZones />
          <Divider.Light />
          <RaceFueling />
          <Divider.Light />
          <Accuracy />
        </>
      )}
    </Layout>
  )
}
