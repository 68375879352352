import React from "react"
import IconLinkedin from "../../assets/images/linkedin.inline.svg"
import IconFacebook from "../../assets/images/facebook.inline.svg"
import IconInstagram from "../../assets/images/instagram.inline.svg"
import IconYoutube from "../../assets/images/youtube.inline.svg"
import Logo from "../../assets/images/logo-small.inline.svg"
import Container from "./Container"
import { MEDIA_QUERIES } from "../../config"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <footer
      css={`
        box-shadow: inset 0 4px 5px 0 rgba(25, 25, 25, 0.15);
        background-color: rgba(25, 25, 25, 0.05);

        a {
          text-decoration: none;
          color: var(--text);
          transition: all 250ms ease;

          &:hover {
            color: #0095ff;
          }
        }
      `}
    >
      <Container
        compact
        css={`
          padding: 60px 0;
          display: flex;
          background: none;

          ${MEDIA_QUERIES.belowDesktop} {
            flex-direction: column;
            align-items: normal;
          }
        `}
      >
        <div
          css={`
            display: flex;
            align-items: flex-end;

            ${MEDIA_QUERIES.belowMobile} {
              flex-direction: column;
              align-items: flex-start;

              & > * {
                margin-bottom: 40px;
              }
            }
          `}
        >
          <Link to="/">
            <Logo
              css={`
                fill: var(--text);
                opacity: 0.6;
                margin-right: 16px;
              `}
            />
          </Link>

          <div
            css={`
              opacity: 0.7;
            `}
          >
            PNOĒ <br />© Copyright 2020 |{" "}
            <a href="https://www.mypnoe.com/privacy-policy/">Privacy Policy</a>
          </div>
        </div>
        <ul
          css={`
            padding: 0;
            margin: 0;
            align-self: flex-end;

            display: flex;
            flex: 1;
            justify-content: flex-end;
            list-style: none;
            opacity: 0.7;

            ${MEDIA_QUERIES.belowDesktop} {
              align-self: flex-start;
            }

            ${MEDIA_QUERIES.belowMobile} {
              flex-direction: column;

              li:not(:last-child) {
                margin-bottom: 40px;
              }
            }

            li:not(:last-child) {
              margin-right: 32px;
            }

            a {
              display: flex;
              align-items: center;

              svg {
                margin-right: 10px;
                opacity: 0.7;
                fill: currentColor;
              }

              &:hover {
                svg {
                  fill: currentColor;
                }
              }
            }
          `}
        >
          <li>
            <a href="https://www.facebook.com/PnoeAnalytics/">
              <IconFacebook />
              Facebook
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/pnoe_analytics/">
              <IconInstagram />
              Instagram
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCNqCL_TkoFfV1aF3W63Zqsw">
              <IconYoutube />
              Youtube
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/pnoe/">
              <IconLinkedin />
              LinkedIn
            </a>
          </li>
        </ul>
      </Container>
    </footer>
  )
}
