import styled, { css } from "styled-components"

const Button = styled.button`
  margin: ${props =>
    `${props.mt || 0}px ${props.mr || 0}px ${props.mb || 0}px ${
      props.ml || 0
    }px`};
  border-radius: 8px;
  box-shadow: 5px 5px 10px 0 var(--color-shadow-one),
    -5px -5px 10px 0 var(--color-shadow-two);
  padding: 8px 24px;
  border: 2px solid #1979ff;
  font-size: 16px;
  font-weight: bold;
  color: #0095ff;
  background-color: var(--main);
  cursor: pointer;
  outline: none;
  transition: box-shadow 200ms linear;
  box-sizing: border-box;
  display: inline-block;
  text-decoration: none;

  &:hover {
    box-shadow: 1px 1px 4px 0 var(--color-shadow-one),
      -1px -1px 4px 0 var(--color-shadow-two);
  }
`

Button.Plain = styled(Button)`
  box-shadow: none;
  background: transparent;
  border: 2px solid #d6d5d5;
  color: var(--main);
  padding: 12px 28px;
  transition-duration: 200ms;
  transition-timing-function: ease;
  transition-property: color, background-color;

  &:hover {
    box-shadow: none;
    background: var(--main);
    color: var(--text);
  }
`

Button.RaisedDumb = styled.button`
  padding: 10px 12px;
  border-radius: 10px;
  box-shadow: ${props =>
    props.theme === "dark"
      ? "5px 5px 10px 0 #0a0a0a, -5px -5px 10px 0 #242424"
      : "5px 5px 10px 0 rgba(25, 25, 25, 0.2), -5px -5px 10px 0 #fffefe"};
  background-color: var(--main);
  font-size: 16px;
  border: none;
  line-height: 1.5;
  color: var(--text);
  transition: box-shadow 250ms ease, opacity 250ms ease, border-color 250ms ease;
  box-sizing: border-box;
  outline: none;
  border: 2px solid transparent;
  display: flex;

  svg.auto-svg {
    fill: var(--text);
    width: 16px;
    height: 16px;
    margin-right: 8px;
    height: 100%;
    align-self: center;
  }
`

Button.Raised = styled(Button.RaisedDumb)`
  opacity: 0.4;

  ${props =>
    props.active
      ? css`
          border: 2px solid #0095ff;
          opacity: 1;
        `
      : ""}

  &:hover:enabled {
    box-shadow: ${props =>
      props.theme === "dark"
        ? "2px 2px 4px 0 #0a0a0a, -2px -2px 5px 0 #242424"
        : "5px 5px 10px 0 #c2c6cc, -5px -5px 10px 0 #ffffff"};
    opacity: 1;
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.4;
  }
`

export default Button
