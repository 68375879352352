import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Container from "./Container"
import Button from "../common/Button"
import { Link } from "gatsby"

const DATA = {
  title: "Find your limiting factor",
  descriptions: [
    "PNOĒ provides a deeper look into your physiology than traditional lab-based VO2max and metabolic tests, thanks to our AI-powered analysis. Trained on 50,000+ metabolic profiles, our algorithms analyze all organs participating in oxygen transport and find the ones keeping you from optimal performance. We then craft the nutrition, training, and recovery your biology needs.",
  ],
  button: {
    name: "Book your test now",
    to: "/payment",
  },
}

const Image = () => (
  <Container.Image align="center">
    <StaticImage
      src="../../assets/images/why-get-tested/limiting-factor.png"
      alt="limiting factor graph"
      width={474}
      placeholder="blurred"
    />
  </Container.Image>
)

export default function LimitingFactor() {
  return (
    <Container bleed="both" py="10rem">
      <div>
        <Container.Title>{DATA.title}</Container.Title>
        <Container.Description>{DATA.descriptions[0]}</Container.Description>
        <Container.Description>{DATA.descriptions[1]}</Container.Description>
        <Button as={Link} to={DATA.button.to}>
          {DATA.button.name}
        </Button>
      </div>
      <Image />
    </Container>
  )
}

LimitingFactor.Mobile = () => (
  <Container bleed="both" py="10rem">
    <Container.Title>{DATA.title}</Container.Title>
    <Container.Description>{DATA.descriptions[0]}</Container.Description>
    <Container.Description>{DATA.descriptions[1]}</Container.Description>
    <Image />
    <Button as={Link} to={DATA.button.to} mt={24}>
      {DATA.button.name}
    </Button>
  </Container>
)
