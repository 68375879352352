import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Container from "./Container"

const DATA = {
  title: "Nutrition & Race fueling",
  descriptions: [
    "PNOĒ measures your fat, carbohydrate, and calorie burn with clinical precision and determines the optimal nutrition during, before, and after the race based on your biology and sport. The PNOĒ app then syncs with your metabolic profile allowing you to accurately track your fuel usage and nutrition needs during the race.",
  ],
  button: {
    name: "Book your test now",
    to: "/payment",
  },
}

export default function RaceFueling() {
  return (
    <Container bleed="right">
      <Container.Absolute>
        <Container.Title width="50rem">{DATA.title}</Container.Title>
        <Container.Description width="50rem">
          {DATA.descriptions[0]}
        </Container.Description>
      </Container.Absolute>
      <Container.Image align="right">
        <StaticImage
          src="../../assets/images/why-get-tested/race-fueling.png"
          alt="bicycle exercise"
          width={846}
          placeholder="blurred"
        />
      </Container.Image>
    </Container>
  )
}

RaceFueling.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="50rem">{DATA.title}</Container.Title>
    <StaticImage
      src="../../assets/images/why-get-tested/race-fueling-mobile.png"
      alt="bicycle exercise"
      width={846}
      placeholder="blurred"
    />
    <Container.Description width="50rem" mt={24}>
      {DATA.descriptions[0]}
    </Container.Description>
  </Container>
)
